<template>
  <div class="multiselect">
    <div
      class="selected"
      @touchstart="showOptions = !showOptions"
      @mouseenter="showOptions = true"
      @mouseleave="showOptions = false"
    >
      <span v-if="hasItems" v-html="showSelection"></span
      ><span v-else>{{ placeholder }}</span>
    </div>
    <div v-show="showOptions" class="options" @mouseenter="showOptions = true" @mouseleave="showOptions = false">
      <label v-for="option in options" :key="option">
        <input type="checkbox" :value="option" v-model="selected" />
        {{ option }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: () => "Vyber jednu nebo více možností",
    },
  },
  data() {
    return {
      selected: this.value,
      showOptions: false,
    };
  },
  watch: {
    value(newValue) {
      this.selected = newValue;
    },
  },
  methods: {
    updateValue() {
      this.$emit("input", this.selected);
    }
  },
  created() {
    this.updateValue();
  },
  updated() {
    this.updateValue();
  },
  computed: {
    hasItems() {
      return this.selected.length > 0;
    },
    showSelection() {
        let numSelected = this.selected.length;
        let text =  this.selected.join(", ");
        text = text.length > 30 ? `${text.substring(0,30)}...` : text;
        return `${text} <strong>(${numSelected})</strong>`
    }
  },
  
};
</script>

<style>
.multiselect {
  position: relative;
  background-color: #fff;
  font-size: 0.8rem;
  margin: 4px 0;
}

/* Add the icon to the input */
.multiselect::after {
  content: "⌄";
  position: absolute;
  top: 12px;
  right: 8px;
  transform: translateY(-50%);
}

.selected {
  border: 1px solid var(--mid-gray);
  border-radius: 8px;
  padding: 8px 16px;
  box-sizing: border-box;
  cursor: pointer;
}

.selected:focus,
.selected:active {
  border: 1px solid var(--dark-gray);
  outline: none;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid var(--mid-gray);
  border-top: none;
  border-radius: 0 0 8px 8px;
  padding: 8px;
  box-sizing: border-box;
  z-index: 100;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 4px;
  cursor: pointer;
}
</style>
