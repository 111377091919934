<template>
  <div class="content">
    <h2>Kalendář akcí</h2>
    <div class="calendar">
      <div>
        <v-calendar
          :attributes="events"
          timezone="Europe/Prague"
          ref="calendar"
          is-expanded
          @update:from-page="moved"
        />
      </div>
      <div>
        <div
          v-for="(event, i) in filteredEvents"
          :key="event.key + i"
          class="event"
        >
          <div class="event__date">
            <div>
              <div class="event__day">
                {{ event.dates[0].start.getDate() }}
              </div>
              <div class="event__month">
                {{ months[event.dates[0].start.getMonth()] }}
              </div>
            </div>
            <div v-if="event.dates[0].end.toDateString() !== event.dates[0].start.toDateString()" class="event__date-divider">
              –
            </div>
            <div
              v-if="event.dates[0].end.toDateString() !== event.dates[0].start.toDateString()"
            >
              <div class="event__day">
                {{ event.dates[0].end.getDate() }}
              </div>
              <div class="event__month">
                {{ months[event.dates[0].end.getMonth()] }}
              </div>
            </div>
          </div>
          <div>
            <h3>{{ event.name }}</h3>
            <p v-html="event.desc"></p>
            <a v-if="event.attachment" :href="event.attachment" target="_blank"
              >Zobrazit přílohu</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from "v-calendar/lib/components/calendar.umd";
import axios from "axios";
import { rrulestr } from "rrule";

export default {
  name: "Calendar",
  components: {
    "v-calendar": Calendar,
  },
  data() {
    return {
      events: [],
      months: [
        "led",
        "úno",
        "bře",
        "dub",
        "kvě",
        "čvn",
        "čvc",
        "srp",
        "zář",
        "říj",
        "lis",
        "pro",
      ],
      activeDate: {},
      filteredEvents: [],
    };
  },
  created() {
    var colors = [
      "gray",
      "red",
      "orange",
      "yellow",
      "green",
      "teal",
      "blue",
      "indigo",
      "purple",
      "pink",
    ];
    var i = 0;
    axios
      .get("/events.php")
      .then((res) => {
        this.events = res.data
          .map((ev) => {
            var color = colors[i % 10];
            i++;
            var attachment = Object.keys(ev).filter((str) => {
              return str.substr(0, 6) == "ATTACH";
            })[0];

            var dates = [
              {
                start: new Date(ev.DTSTART.date),
                end: new Date(new Date(ev.DTEND.date) - 1),
              },
            ];

            if (ev.RRULE) {
              var rule = rrulestr(
                `DTSTART:${this.icalDateFormat(
                  new Date(ev.DTSTART.date)
                )}\nRRULE:${ev.RRULE}`
              );
              var condition;
              if (!rule.options.until && !rule.options.count) {
                condition = function (date, i) {
                  return i < 52;
                };
              }
              dates = rule.all(condition).map((date) => {
                var evDate = new Date(date);
                return {
                  start: evDate,
                  end: evDate,
                };
              });
            }

            return {
              key: ev.UID,
              dot: color,
              dates: dates,
              popover: {
                label: ev.SUMMARY,
              },
              name: ev.SUMMARY,
              desc: ev.DESCRIPTION,
              attachment: ev[attachment],
            };
          })
          .sort(function (a, b) {
            return a.dates[0].start - b.dates[0].start;
          });

        this.filterEvents();
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    moved: function (activeDate) {
      this.activeDate = activeDate;
      this.filterEvents();
    },
    filterEvents: function () {
      this.filteredEvents = [];
      this.events.forEach((ev) => {
        ev.dates.forEach((el) => {
          var checkDate =
            ( el.start.getMonth() + 1 <= this.activeDate.month &&
            el.start.getFullYear() <= this.activeDate.year ) &&
            ( el.end.getMonth() + 1 >= this.activeDate.month &&
            el.end.getFullYear() >= this.activeDate.year);
          if (checkDate) {
            var event = { ...ev };
            event.dates = [el];
            this.filteredEvents.push(event);
          }
        });
      });
      this.filteredEvents.sort(function (a, b) {
        return a.dates[0].start - b.dates[0].start;
      });
    },
    icalDateFormat: function (date) {
      const year = date.getUTCFullYear();
      const month = this.pad(date.getUTCMonth() + 1);
      const day = this.pad(date.getUTCDate());
      const hour = this.pad(date.getUTCHours());
      const minute = this.pad(date.getUTCMinutes());
      const second = this.pad(date.getUTCSeconds());
      return `${year}${month}${day}T${hour}${minute}${second}Z`;
    },
    pad: function (i) {
      return i < 10 ? `0${i}` : `${i}`;
    },
  },
};
</script>

<style>
.calendar {
  display: grid;
  grid-template-columns: 256px 1fr;
  grid-gap: 24px;
}

@media screen and (max-width: 768px) {
  .calendar {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}

.event {
  border: 1px solid var(--mid-gray);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 160px 1fr;
  grid-gap: 16px;
  background-color: #fff;
}

.event__date {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  font-size: 1.8rem;
  font-family: "skautbold";
  text-align: center;
  color: #000;
}

.event__date-divider {
  padding: 38px 8px 20px 8px;
}

@media screen and (max-width: 480px) {
  .event__date {
    max-width: 64px;
  }
  .event__date-divider {
    padding: 0;
}
}


.event__day {
  font-size: 3rem;
}

.event h3 {
  margin-top: 16px;
}

.event p {
  margin-bottom: 0;
}
</style>
