<template>
  <div class="content">
    <h2>Projekty a výzvy</h2>
    <div v-html="content.content"></div>
    <div class="project__filters">
      <div class="--half-width">
        <input
          type="text"
          v-model="filters.search"
          placeholder="Vyhledat 🔍︎"
          class="--full-width"
        />
      </div>
      <div class="--one-fourth-width">
        <multiselect
          :options="availableTopics"
          v-model="filters.topic"
          placeholder="Oblast"
        ></multiselect>
      </div>
      <div class="--one-fourth-width">
        <multiselect
          :options="['Projekt', 'Výzva']"
          v-model="filters.type"
          placeholder="Projekt nebo výzva"
        ></multiselect>
      </div>
    </div>
    <div class="masonry">
      <div :key="rowIndex" v-for="(row, rowIndex) in rows" class="flexcolumn">
        <div
          v-for="(post, postIndex) in row"
          :key="rowIndex + ':' + postIndex"
          class="project"
          @click="openModal(post)"
        >
          <div v-if="post.img" class="project__img-container">
            <img :src="`/img/projekty-a-vyzvy/${post.img}`" alt="" class="project__img" />
             <div v-if="post.troop_friendly==1" class="project__badge">Oddíl Friendly</div>
          </div>
          <div class="project__text">
            <h3 v-html="post.name"></h3>
            <div class="project__categories">
              <span class="project__type">{{ post.type[0] }}</span>
              <span
                v-for="(topic, i) in post.topic"
                :key="'topic' + i"
                :class="getTopicClass(topic)"
                >{{ topic }}</span
              >
            </div>
            <div v-html="makeIntroText(post.content, 40)"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="project__end-of-page --text-center">
      <Loader
        :show="loader.show"
        :type="loader.type"
        class="loader--inline"
      ></Loader>
      <span v-if="loader.type == 'error'">Není dostupný žádný další obsah</span
      ><span v-if="loader.type == 'loading' && !loader.show"
        ><em
          >Pro načtení více článků scrolluj dolů nebo klikni
          <span class="link" @click="getPosts()">sem</span>...</em
        ></span
      >
      <modal
        ref="modal"
        v-if="showPost"
        v-on:close-modal="closeModal()"
        class="modal-fixed-sm modal-scrollable"
      >
        <div class="modal__content">
          <div class="project__modal">
            <img v-if="myPost.img"
              :src="`/img/projekty-a-vyzvy/${myPost.img}`"
              alt="Obrázek projektu"
              class="poject__modal-image"
            />
            <h3 class="project__modal-heading">{{ myPost.name }}</h3>
            <div class="project__categories">
              <span class="project__type">{{ myPost.type[0] }}</span>
              <span
                v-for="(topic, i) in myPost.topic"
                :key="'topic' + i"
                :class="getTopicClass(topic)"
                >{{ topic }}</span
              >
              <span v-if="myPost.troop_friendly==1" class="project__badge-category">Oddíl Friendly</span>
            </div>
            <div v-html="myPost.content"></div>
            <div v-if="myPost.tip.length > 0 && myPost.tip[0] != ''" class="project__modal-aims">
              <h4>Tip<span v-if="myPost.tip.length > 1">y</span></h4>
              <ul>
                <li v-for="(tip, i) in myPost.tip" :key="'tip' + i">
                  {{ tip }}
                </li>
              </ul>
            </div>
            <div v-if="myPost.aim.length > 0 && myPost.aim[0] != ''" class="project__modal-aims">
              <h4>Cíle</h4>
              <ul>
                <li v-for="(aim, i) in myPost.aim" :key="'aim' + i">
                  {{ aim }}
                </li>
              </ul>
            </div>
            <div class="project__modal-attachment btn-group" v-if="myPost.attachment">
              <a :href="`https://drive.google.com/uc?id=${myPost.attachment}&export=download`" target="_blank" rel="noopener noreferrer" class="btn btn-red">Stáhnout kartu projektu</a><a :href="`https://drive.google.com/file/d/${myPost.attachment}/view?usp=share_link`" target="_blank" rel="noopener noreferrer" class="btn">Přidat komentář *</a>
              <p class="project__modal-attachment-disclaimer">
                * V rámci pilotního testování je možné komentovat jednotlivé projekty a výzvy. Po kliknutí na [Přidat komentář] se dostanete do prostředí Google Drive, kde je možné i bez přihlášení přidávat k vybraným částem textu komentáře.
              </p>
              <p class="project__modal-attachment-disclaimer">
                Komentáře můžete přidávat kliknutím na ikonu 💬 / vybráním části textu a kliknutím na ikonu 💬 / kliknutím pravým tlačítkem myši a výběrem "Přidat komentář".
              </p>
            </div>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "@/components/Multiselect.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: "Projects",
  data() {
    return {
      showPost: false,
      myPost: {
        name: "",
        content: "",
      },
      filters: {
        id: "",
        slug: "",
        search: "",
        type: [],
        topic: [],
        attachment: "",
      },
      limit: 12,
      posts: [],
      nextPage: 1,
      loader: {
        show: true,
        type: "loading",
      },
      content: {
        id: null,
        slug: null,
        name: null,
        img: null,
        content: null,
      },
      optionTopics: [
            'Fyzický a duševní rozvoj',
            'Místo ve společnosti',
            'Dobré vztahy',
            'Hledání pravdy',
            'Vztah k přírodě',
          ],
      subtopics: {
          'Fyzický a duševní rozvoj': [
            '– zdravý život',
            '– tvořivost',
            '– praktičnost',
            '– odpovědnost za svůj růst',
          ],
          'Místo ve společnosti': [
            '– aktivní občanství',
            '– rozmanitá společnost',
            '– propojený svět',
          ],
          'Dobré vztahy': [
            '– zdravé vztahy',
            '– zájem o druhé',
            '– týmová spolupráce',
            '– komunikační dovednosti',
          ],
          'Hledání pravdy': [
            '– duchovní život',
            '– skautské hodnoty v životě',
          ],
          'Vztah k přírodě': [
            '– život v přírodě',
            '– odpovědnost za přírodu',
            '– vnímání přírody',
          ],
        }
    };
  },
  methods: {
    getPosts: function (newQuery = false) {
      this.loader.show = true;
      if (newQuery) {
        this.nextPage = 1;
        this.loader.type = "loading";
      }
      axios
        .get(`/projects.php${this.urlParams}`)
        .then((res) => {
          setTimeout(() => {
            if (newQuery) {
              this.posts = res.data;
              this.loader.show = true;
            } else {
              this.posts = this.posts.concat(res.data);
            }
            if (
              res.headers["x-wp-totalpages"] == this.nextPage ||
              res.headers["x-wp-totalpages"] == 0
            ) {
              this.loader.type = "error";
            } else {
              this.nextPage++;
              this.loader.show = false;
            }

            this.updateRows(); // update rows when new posts are fetched
          }, 150);
        })
        .catch((e) => {
          console.log("Chyba: ", e);
        });
    },
    scroll: function () {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight + 100 >
          document.documentElement.offsetHeight;

        if (
          bottomOfWindow &&
          !this.loader.show &&
          this.loader.type == "loading"
        ) {
          this.getPosts();
        }
      };
    },
    updateRows() {
      this.$forceUpdate();
    },
    openModal(post) {
      this.$router.push({ path: '/projekty-a-vyzvy/' + post.slug });
      this.myPost = post;
      this.showPost = true;
    },
    closeModal() {
      this.$router.push({ path: '/projekty-a-vyzvy' });
      this.showPost = false;
    },
    getTopicClass(item) {
      // Check if item is a subtopic
      if (item.startsWith('– ')) {    
        // Find the parent topic
        for (let topic in this.subtopics) {
          if (this.subtopics[topic].includes(item)) {
            return [
              'project__topic',
              `project__topic--${this.topicColorMap[topic]}`
            ];
          }
        }
      } else {
        // If it's a main topic
        return [
          'project__topic',
          `project__topic--${this.topicColorMap[item]}`
        ];
      }
    },
    makeIntroText(text, words) {
      if (text.split(" ").length > words) {
        return text.split(" ").splice(0, words).join(" ") + "...";
      } else {
        return text;
      }
    },
  },
  created() {
    axios
        .get("/content.php?slug=projekty-a-vyzvy")
        .then((res) => {
          if (res.data.length) {
            this.content = res.data[0];
          }
        })
        .catch(() => {
          this.$router.replace({
            name: "Home",
          });
        });

    this.getPosts();
    if (this.$route.params.slug) {

      axios
        .get(`/projects.php?slug=${this.$route.params.slug}`)
        .then((res) => {
          if (res.data.length == 0) {
            this.showPost = false;
            this.$router.push({ path: '/projekty-a-vyzvy' });
          }
          else {
            this.myPost = res.data[0];
            this.showPost = true;
          }
        })
        .catch((e) => {
          console.log("Chyba: ", e);
        });
    }
  },
  computed: {
    urlParams() {
      let params = [
        ...Object.entries(this.filters),
        ["limit", this.limit],
        ["page", this.nextPage],
      ]
        .filter(([, value]) => {
          return value !== "" && (!Array.isArray(value) || value.length > 0);
        }) // filter out empty values and empty arrays
        .map(([key, value]) => {
          value = Array.isArray(value) ? value.join(",") : value;
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join("&");

      return params ? `?${params}` : "";
    },
    rows() {
      const screenWidth = window.innerWidth;
      const numColumns =
        screenWidth < 480
          ? 1
          : screenWidth < 768
          ? 2
          : screenWidth < 1024
          ? 3
          : 4;
      const rows = new Array(numColumns).fill(null).map(() => []);

      this.posts.forEach((post, index) => {
        const columnIndex = index % numColumns;
        rows[columnIndex].push(post);
      });

      return rows;
    },
    topicColorMap() {
      return {
        'Fyzický a duševní rozvoj' : 'yellow',
        'Místo ve společnosti' : 'blue',
        'Dobré vztahy' : 'red',
        'Hledání pravdy' : 'orange',
        'Vztah k přírodě' : 'green'
      }
    },
    availableTopics() {
    let options = [...this.optionTopics];

    // Pokud není vybráno žádné téma, vrátíme původní možnosti
    if (!this.filters.topic || this.filters.topic.length === 0) {
      return options;
    }

    // Projdeme všechna vybraná témata
    this.filters.topic.forEach(selectedTopic => {
      if (this.subtopics[selectedTopic]) {
        // Najdeme index pro každé vybrané téma
        const topicIndex = options.indexOf(selectedTopic);

        // Pokud jsme našli téma v možnostech, vložíme podtémata hned za něj
        if (topicIndex !== -1) {
          options.splice(topicIndex + 1, 0, ...this.subtopics[selectedTopic]);

          // Nyní musíme aktualizovat indexy pro další vybraná témata, protože pole se změnilo
          this.filters.topic.forEach((otherTopic, idx) => {
            if (otherTopic !== selectedTopic && this.subtopics[otherTopic]) {
              const otherIndex = options.indexOf(otherTopic);
              if (otherIndex !== -1 && otherIndex < topicIndex) {
                this.filters.topic[idx] = options[otherIndex];
              }
            }
          });
        }
      }
    });

    // Odstraníme duplikáty, které mohou vzniknout, pokud jsou podtémata již v seznamu
    options = options.filter((option, index, self) => {
      return self.indexOf(option) === index;
    });

    return options;
      
      }
  },
  watch: {
    filters: {
      handler: function () {
        this.getPosts(true);
      },
      deep: true,
    },
    'filters.topic': {
    handler(newTopics, oldTopics) {
      // Zjistíme, které téma bylo odznačeno
      const unselectedTopic = oldTopics.find(oldTopic => !newTopics.includes(oldTopic));

      // Pokud nějaké nadřazené téma bylo odznačeno
      if (unselectedTopic && this.subtopics[unselectedTopic]) {
        // Filtrujeme podtémata daného nadřazeného tématu z výběru
        this.filters.topic = this.filters.topic.filter(topic => 
          !this.subtopics[unselectedTopic].includes(topic) &&
          topic !== unselectedTopic
        );
      }
    },
    deep: true
  }
  },
  mounted() {
    this.scroll();
    window.addEventListener("resize", this.updateRows);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateRows);
  },
  components: {
    Multiselect,
    modal: Modal,
  },
};
</script>

<style>
.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}

.masonry {
  column-count: 4;
}

.flexcolumn {
  display: flex;
  flex-direction: column;
}

.project {
  break-inside: avoid-column;
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid var(--mid-gray);
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
}

.project__filters {
  padding: 16px 0;
}

.project__filters > div {
  display: inline-block;
}

.project__img-container {
  position: relative;
  width: 100%;
}

.project__badge {
  position: absolute;
  top: 8px;
  right: -48px; /* Adjusted for better visibility */
  background: var(--mid-red);
  color: white;
  padding: 5px 25px; /* Increased padding for larger appearance */
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  clip-path: polygon(25px 0, calc(100% - 25px) 0, 100% 25px, 100% 100%, 0 100%, 0 25px);
  border-radius: 2px;
  /* border-bottom-left-radius: 5px; 
  border-bottom-right-radius: 5px; 
  border-top-left-radius: 20px; 
  border-top-right-radius: 20px; */
}

.project__img {
  display: block;
  width: 100%;
  height: auto;
  /*margin-bottom: 8px;*/
}

.project__text {
  padding: 16px;
}

.project__categories {
  margin-bottom: 16px;
}

.project__type {
  font-size: 0.8rem;
  background-color: var(--mid-gray);
  border: none;
  color: black;
  padding: 3px 6px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
}

.project__topic {
  font-size: 0.8rem;
  background-color: var(--mid-gray);
  border: none;
  color: black;
  padding: 3px 6px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
}

.project__badge-category {
  font-size: 0.8rem;
  background-color: var(--dark-red);
  border: none;
  color: white;
  padding: 3px 6px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
}

.project__topic:first-of-type {
  margin-left: 0;
}

.project__end-of-page {
  margin: 16px 0;
}

@media only screen and (max-width: 1024px) {
  .masonry {
    /* grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)); */
    column-count: 3;
  }
}

@media only screen and (max-width: 768px) {
  .masonry {
    /* grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)); */
    column-count: 2;
  }
}

@media only screen and (max-width: 480px) {
  .masonry {
    /* grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)); */
    column-count: 1;
  }
}

.project__modal {
  text-align: left;
}

.poject__modal-image {
  max-height: 450px;
  object-fit: scale-down;
  border-radius: 8px;
}

.project__modal-heading {
  padding-top: 16px;
}

.project__modal-attachment-disclaimer {
  font-size: 0.8rem;
  color: var(--mid-dark-gray);
}

.project__topic--yellow {
  background-color: #ffeca0;
  /* color: #fad17a; */
}

.project__topic--orange {
  background-color: #ffd9a0;
  /* color: #fad17a; */
}

.project__topic--red {
  background-color: #f3a497;
  /* color: #fad17a; */
}

.project__topic--blue {
  background-color: #aac3e0;
  /* color: #fad17a; */
}

.project__topic--green {
  background-color: #cbe5d2;
  /* color: #fad17a; */
}
</style>
